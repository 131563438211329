import React from 'react';
import { Deck, Slide, Heading, Text, Link, Image, FlexBox } from 'spectacle';
import brownConnieKiss from './images/brown-connie-kiss.png';
import cascadaLodge from './images/cascada-lodge.jpg';
import dinner from './images/dinner.png';
import roadtrip from './images/roadtrip.jpg';
import packageImage from './images/package.png';
import presents from './images/presents.png';
import brownConniePassionateKiss from './images/brown-connie-passionate-kiss.png';
import brownSexy from './images/brown-sexy.gif';
import brownConnieILovU from './images/brown-connie-i-lov-u.png';

interface SlideImageProps {
  src?: string;
  alt: string;
  height?: string;
}

const SlideImage = ({ src, alt, height }: SlideImageProps): JSX.Element => (
  <Image
    src={src}
    alt={alt}
    style={{
      flexAlign: 'center',
      margin: '0 auto',
      maxWidth: '100%',
      ...(height ? { height } : { maxHeight: '70%' })
    }}
  />
);

const HappyAnniversary = (): JSX.Element => (
  <>
    <Heading>Feliz aniversario Vale! ❤️</Heading>
    <SlideImage src={brownConnieKiss} alt={'brownConnieKiss'} height='80%' />
  </>
);

const HappyAnniversary2 = (): JSX.Element => (
  <>
    <Heading>Eres una mujer impresionante</Heading>
    <Text>
      Eres mi compañera definitiva, cada día me sorprendes y me enamoro más y más, soy muy afortunado de tenerte a mi lado.
    </Text>
    <Text>
      Tienes todo mi amor hoy y siempre mi 💝 linda. 🥰🥰🥰
    </Text>
  </>
);

const happyAnniversary: JSX.Element[] = [
  <HappyAnniversary />,
  <HappyAnniversary2 />
]

const PresentsIntro = (): JSX.Element => (
  <>
    <Heading>Te quiero dar 4 regalos este aniversario</Heading>
    <SlideImage src={presents} alt={'presents'} />
  </>
);

const PresentDomain = (): JSX.Element => (
  <>
    <Heading>Un dominio de internet! 👨‍💻🤓</Heading>
    <Text>
      Podemos hacer emails, subir un blog, hacer una página, un mundo de posibilidades. 😊
      También subí este sitio que ves ahora con ese dominio. ⬆️
      (<Link href='https://www.nic.cl/registry/Whois.do?d=lopezjimenez.cl' target='blank'>lopezjimenez.cl</Link>)
    </Text>
  </>
);

const PresentDinner = (): JSX.Element => (
  <>
    <Heading>Una cena en Isidora Goyenechea</Heading>
    <SlideImage src={dinner} alt={'dinner'} />
  </>
);

const PresentRomanticGetawayIntro = (): JSX.Element => (
  <>
    <Heading>Una escapada romántica 💑</Heading>
    <SlideImage src={roadtrip} alt={'roadtrip'} height='50%' />
  </>
);

const PresentRomanticGetawayMap = (): JSX.Element => (
  <>
    <Heading>Vamos al Cajón del Maipo!</Heading>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d212718.47427412725!2d-70.59537182512153!3d-33.58645858116592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x9662cfa1858a4633%3A0xdd571040d10a5480!2sConsejo%20de%20Indias%201379%2C%20Providencia%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!3m2!1d-33.4419448!2d-70.5956453!4m5!1s0x967cd1bffbf46b57%3A0xa7f10f403d5328d4!2sCascada%20Lodge%20-%20Camino%20Al%20Volc%C3%A1n%2031087%2C%20San%20Alfonso%2C%20San%20Jos%C3%A9%20de%20Maipo%2C%20Regi%C3%B3n%20Metropolitana!3m2!1d-33.731130199999996!2d-70.3152065!5e0!3m2!1sen!2scl!4v1606396522445!5m2!1sen!2scl"
      width="600"
      height="450" 
      style={{ border: 0, margin: '0 auto' }}
      aria-hidden="false"
    ></iframe>
  </>
);

const PresentRomanticGetawayHotel = (): JSX.Element => (
  <>
    <Heading>Al hotel Cascada Lodge</Heading>
    <SlideImage src={cascadaLodge} alt={'cascada-lodge'} />    
  </>
);

const PresentRomanticGetawayPackage = (): JSX.Element => (
  <>
    <Heading>Nuestro paquete 🌹</Heading>
    <SlideImage src={packageImage} alt={'package-image'} height='55%' />
  </>
);


const romanticGetaway: JSX.Element[] = [
  <PresentRomanticGetawayIntro />,
  <PresentRomanticGetawayMap />,
  <PresentRomanticGetawayHotel />,
  <PresentRomanticGetawayPackage />
];

const PresentSurprise = (): JSX.Element => (
  <FlexBox flexDirection='column'>
    <Heading>Y uno sorpresa...</Heading>
    <Text>Un 🎁 que te daré el sábado. ❤️‍🔥</Text>
    <FlexBox flexDirection='row' justifyContent="space-between">
      <SlideImage src={brownConniePassionateKiss} alt={'brownConniePassionateKiss'} />
      <SlideImage src={brownSexy} alt={'brownSexy'} />
    </FlexBox>
  </FlexBox>
);

const ILovU = (): JSX.Element => (
  <FlexBox justifyContent='center' style={{ height: '100%' }}>
    <SlideImage src={brownConnieILovU} alt={'brownConnieILovU'} height="70%" />
  </FlexBox>
);

const slides: JSX.Element[] = [
  ...happyAnniversary,
  <PresentsIntro />,
  <PresentDomain />,
  <PresentDinner />,
  ...romanticGetaway,
  <PresentSurprise />,
  <ILovU />
];

const App = (): JSX.Element => (
  <Deck>
    {slides.map((slide, index) => (
      <Slide key={`slide-${index+1}`}>
        {slide}
      </Slide>
    ))}
  </Deck>
);

export default App;
